.switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;
  }

  .switchDescription{
    font: normal normal 400 12px / 14px "Work Sans", sans-serif;
  }

  :global(.ant-form-item){
    margin-bottom: 0 !important;
  }

  :global(.ant-form-item-control-input-content) {
    justify-content: center;
  }
}

.configurationDefaultWrapper {
  margin-bottom: 12px;
  width: 90%;
}

.eiqTextArea {
  height: 100px !important;
  resize: none !important;
}

.communityRules {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}

.italic {
  font-style: italic !important;
}

.label{
  font: normal normal 400 16px/18px "Work Sans", sans-serif !important;
}

.description {
  font: normal normal 400 12px/14px "Work Sans", sans-serif;
}