.column-check-filter {
  background: #FFFFFF;
  width: 208px;
  padding: 8px 12px 12px 8px;
  border-radius: 4px;
  border: 2px solid var(--secondary-stroke-90, #87B6FF);
  background: var(--surface-50, #FFF);
  box-shadow: 0px 0px 0px 2px rgba(135, 182, 255, 0.30);
  box-sizing: border-box;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item {
    margin-bottom: 0!important;
  }

  .eiq-filter-item {
    min-height: 24px;
    margin-top: 8px;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif!important;
    .ant-radio-group {
      min-height: 24px;
      display: flex;
      .ant-radio-wrapper {
        display: flex;
        align-items: center;
        font: normal normal 400 14px/18px 'Work Sans', sans-serif!important;


      }
    }
    .eiq-filter-content {
      margin-left: 32px;
      margin-top: 8px;
    }
  }
  .ant-radio {
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif!important;

    }
  }

  // Date Range
  .eiq-filter-to {
    display: flex;
    align-items: center;
    min-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.column-visibility__header {
  display: flex;
  gap:16px;
  justify-content: space-between;
  align-items: center;

  // title
  > span {
    font: normal normal 500 14px/18px 'Work Sans', sans-serif!important;
    color: #505762;
  }

  // Apply button
  .ant-btn {
    span {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif!important;
      color: #5396FF;
    }
  }
}
