.select-wrapper {
  position: relative;

  .ant-select {
    .ant-select-selection-item {
      padding-left: 30px;
    }

    .ant-select-selection-search {
      padding-left: 30px;
    }
  }

  .ant-select-selection-search {
    background-image: url('../../../../../../assets/icons/search.svg');
    background-repeat: no-repeat;
    background-position: 5px center;
    padding-left: 25px;
  }
}

