.resident-info-container {
    display: flex;
    flex-direction: column;

    .resident-info-update {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .resident-info-update-date {
            font-size: 12px;
            font-weight: 400;
            color: #505762;
            opacity: 0.5;
            display: flex;
            align-items: flex-end;
        }
    }

    .resident-info-contacts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        .resident-info-alert {
            width: 100%;

            div:nth-child(1) {
                margin-bottom: 20px;
                width: calc(100% - 16px);
            }
        }

        .contacts-info-container {
            display: flex;
            flex-direction: row;
            width: 100%;

            .contact-phone {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                font-size: 14px;
                margin-top: 12px;
            }

            div {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .resident-info-contacts {
            display: flex;
            flex-direction: column;

            .contacts-info-container {
                margin-top: 20px;
            }
        }

        .resident-info-update {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;
        }

        .alert-block-container {
            width: auto;
        }
    }

    @media screen and (max-width: 767px) {
        .resident-info-contacts {
            .contacts-info-container {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                div:nth-child(1) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}