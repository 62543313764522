.hotkey-link {
  font: normal normal 400 12px/14px 'Work Sans';
  &__label {
    color: #292B2E;
  }

  .hotkey-link__keybox {
    margin-left: 4px;
  }
}
