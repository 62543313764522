.residentDeleteModalBody {
    font: normal normal 400 14px/18px 'Work Sans';

    .residentName {
        font-weight: 500;
    }
}

.newOwner{
    font: normal normal 400 14px/18px 'Work Sans';
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    align-items: flex-start;
    :global(.ant-select) {
        width: 315px !important;
    }
}

.button {
    width: 153px;
}

.tableError {
    :global(.BaseTable) {
        border: 1px solid #ff4d4f;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.3);
        border-radius: 8px;
    }

}