.resident-move-modal {
  .table-title {
    margin: 12px 0;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  }

  .table-wrapper {
    margin-bottom: 15px;

    .cc-table {
      min-height: initial !important;
    }
  }

  .full-width {
    .ant-select {
      width: 100%;

      .ant-select-selector {
        width: 100%;
      }
    }
  }
  .host_name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}
