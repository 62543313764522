.phone-container {
  padding: 4px 8px;
  border: 2px solid #fff;
  box-sizing: border-box;
  display: block;
  width: 100%;
  &:hover {
    border-radius: 4px;
    border-color: #2f7df6;
    transition: all 0.3s;
  }
}

.dial-phones {
  max-height: 250px;
  overflow-y: auto;
}
