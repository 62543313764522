.general-info-delete-modal {
  .body {
    font: normal normal 400 14px/18px 'Work Sans';

    .streetName {
      font-weight: 500;
    }

    .text {
      display: block;
      padding-right: 40px;
    }
  }

  .button {
    width: 203px;
  }
}
