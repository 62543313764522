.accounts-layout {
  margin: -20px -24px -24px -24px !important;
}

.account-info-field {
  margin-bottom: 15px;
}

.account-name {
  display: flex;
  flex-direction: row;
  gap: 5px;
  .ant-form-item {
    width: 100%;
  }
  .community-identifier {
    padding-top: 24px;
  }
}
