.search-filter-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  min-height: 32px;
}
.search-filter {
  max-width: 384px;
  width: 100%;

  @media screen and (max-width: 993px)  {
    max-width: 100%;
  }
}

.search-filter.adaptive-search {
  @media screen and (max-width: 993px)  {
    max-width: 100%;

    .ant-input-affix-wrapper {
      max-width: 34px;
      padding-left: 8px;
    }
    .ant-input-affix-wrapper-focused {
      max-width: 100%!important;
      width: 100%;
    }
  }
}
