.missingTrafficLogRow {
  background-color: #ffa39e !important;
}

.cancelButton {
  background-color: #e86056 !important;
}

.cancelButton:disabled {
  color: #f6f8fa !important;
}

.approveButton:disabled {
  color: #f6f8fa !important;
  background-color: #1677ff !important;
}
