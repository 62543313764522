.print {
    position: absolute;
    z-index: -99999;
    top: -3000px;
    left: -3000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.printBody {
    width: 1024px;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;

    .printContent {
        margin: 0 12px;

        div {
            margin: 12px 0;
        }

        .name {
            font: normal normal 500 14px/18px 'Work Sans', sans-serif;
        }
    }
}

.printBody :global(.cc-table) {
    min-height: 25cm !important;

    :global(.BaseTable) {
        box-shadow: none;
    }

    @media print {
        :global(.cc-column-scroll) {
            display: none;
        }
    }
}