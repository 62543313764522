.streetDeleteModalBody {
    margin-bottom: 15px;
    font: normal normal 400 14px/18px 'Work Sans';

    .streetName {
        font-weight: 500;
    }
}

.button {
    width: 153px;
}

.buttonFullWidth {
    width: 203px;
}
