.eiq-select-days {
    padding-left: 0;
    padding-right: 0;
}

.eiq-select-days .ant-select-tree-checkbox-inner {
    &:after {
        background-color: transparent !important;
    }
}

.eiq-select-days .ant-select-tree-treenode {
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid #F0F0F0;
    padding: 4px 4px 4px 8px !important;

    &:hover {
        background-color: #C1D8FC;
    }

    .ant-select-tree-indent {
        display: none;
    }

    .ant-select-tree-switcher {
        display: none;
    }
}
