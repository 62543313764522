.infoBlockContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid rgb(80, 87, 98, 0.5);
  padding: 8px 0 8px 16px;
  width: calc(100% - 16px);

  .clubIdContainer {
    .infoBlockField {
      max-width: 140px;
      width: 100%;
    }
  }

  .vacationContainer {
    .infoBlockField {
      max-width: 303px;
      width: 100%;
    }
  }

  .infoLine {
    display: flex;
    flex-direction: row;
    gap: 23px;
    margin-top: 16px;
  }

  .infoLine:nth-child(1) {
    margin-top: 0 !important;
  }

  .communicationConsent {
    display: flex;
    flex-direction: column;
  }
}

.relatedToContainer {
  margin-bottom: 20px;

  .relatedTo {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .infoBlockContainer {
    .clubIdContainer {
      flex-wrap: wrap;

      .infoBlockField:nth-child(1) {
        width: 100%;
        max-width: none;
      }

      .infoBlockField:nth-child(2) {
        width: 30%;
        max-width: none;
      }

      .infoBlockField:nth-child(3) {
        width: 60%;
      }
    }

    .vacationContainer {
      .infoBlockField:nth-child(1) {
        width: 30%;
        max-width: none;
      }

      .infoBlockField:nth-child(2) {
        width: 60%;
      }
    }
  }
}
