.citation-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 480px) {
    height: 200px;
  }
}

.open-citations-btn {
  cursor: pointer;
}