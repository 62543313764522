@mixin font-16 {
  // Search input
  .ant-input-affix-wrapper {
    height: 40px;
  }

  .search-filter-wrapper {
    input {
      font: normal normal 500 16px/normal 'Work Sans', sans-serif;
    }
  }

  .adaptive-search {
    max-width: 668px;
  }

  // Table
  .BaseTable__header-cell-text {
    font: normal normal 500 18px/normal 'Work Sans', sans-serif!important;
  }

  .BaseTable__row-cell-text {
    font: normal normal 400 18px/normal 'Work Sans', sans-serif!important;
  }

  .BaseTable__row-cell {
    font: normal normal 400 18px/normal 'Work Sans', sans-serif!important;
  }

  .eiq-tag {
    font: normal normal 400 14px/14px 'Work Sans', sans-serif;
    height: unset;
    display: inline-flex;
    align-items: center;
  }

  // Buttons
  .ant-btn {
    font: normal normal 500 18px/normal 'Work Sans', sans-serif!important;
    height: 40px;
  }

  .admit-resident-btn {
    font: normal normal 500 18px/normal 'Work Sans', sans-serif !important;
    height: 40px;
  }
  .buttons-font-16 {
    display: flex;
    gap: 8px;
    button.ant-btn {
      font: normal normal 500 16px/normal 'Work Sans', sans-serif !important;
    }
  }

  // Title
  .sub-title {
    font: normal normal 400 16px/14px 'Work Sans', sans-serif !important;
  }

  .phone-number {
    font: normal normal 400 16px/normal 'Work Sans', sans-serif !important;
  }
  .eiq-label {
    font: normal normal 400 14px/14px 'Work Sans', sans-serif !important;
    padding: 4px 6px!important;
  }

  // Forms
  label {
    font: normal normal 400 14px/1 'Work Sans', sans-serif!important;
  }
  .ant-input-affix-wrapper {
    height: unset!important;
    min-height: 40px!important;
    padding-top: 0;
    padding-bottom: 0;
  }
  input {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
    height: unset!important;
    min-height: 40px!important;
    &::placeholder {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
      color:  #505762;
    }
  }
  input[name="password"] {
    height: 30px!important;
  }

  textarea {
    font: normal normal 400 16px/20px 'Work Sans', sans-serif!important;
  }

  // Select
  .ant-select-selection-placeholder {
    font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
    color:  #505762;
  }
  .ant-select-selection-item {
    font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
  }
  .ant-form-item-control {
    .ant-select-selector {
      height: 40px;
    }
  }
  .ant-radio-wrapper > span   {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
  }
  .ant-radio-group {
    gap: 14px;
  }


  .ant-checkbox-group.vertical-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .checkbox-all-group {
    gap: 14px;

    .ant-checkbox-group {
      gap: 14px;
    }
    .checkbox-all-group__label {
      font: normal normal 400 18px/1 'Work Sans', sans-serif!important;
    }
    .ant-checkbox {
      font: normal normal 400 16px/1 'Work Sans', sans-serif!important;
    }
    .ant-checkbox-wrapper > span {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif;
      color: #292B2E;
    }
  }

  .ant-radio-wrapper   {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
  }
  .ant-radio-group {
    gap: 14px;
  }

  label .ant-form-item-control-input-content .ant-checkbox-wrapper > span {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
  }

  .ant-checkbox-wrapper {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
  }

  .ant-checkbox-wrapper > span {
    font: normal normal 400 16px/18px 'Work Sans', sans-serif;
    color: #292B2E;
  }

 .ant-picker {
    padding: 0 6px 0 4px!important;
    input::placeholder {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
    }
    //svg {
    //  width: 20px;
    //  height: 20px;
    //}
  }


}

.eiq-secure-panel {
  font: normal normal 400 14px/18px 'Work Sans';
  color: #505762;
  width: 100%;

  .ant-layout-header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 42px;
    line-height: 42px;
    width: 100%;
    padding-left: 24px !important;
    padding-right: 24px !important;
    background-color: #ffffff;
    // border-bottom: 1px solid red;
    border-bottom: 1px solid #e1e5ea;

    .logo {
      width: 100px;
      margin-bottom: -5px;
    }

    .user-name {
      display: inline-flex;
      color: #505762;
      font: normal normal 400 12px/14px 'Work Sans';
      margin-right: 18px;
      margin-left: 18px;
    }

    .ant-btn.ant-btn-ghost {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-layout {
    min-height: 100%;
  }

  .content-style {
    padding: 20px 24px 24px 24px !important;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 993px) {
      padding: 20px 16px 24px 16px !important;
    }
  }


  .ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .cc-menu-item {
    display: inline-flex !important;
    flex: auto;
    min-width: 0;
    align-items: center;
    padding: 0 !important;
    border-bottom: none;

    .ant-menu-item {
      display: inline-flex;
      padding: 0 !important;
      margin-top: 4px;
      height: 38px;

      &:after {
        content: unset !important;
      }
    }

    .ant-menu-title-content {
      padding: 0 10px 0 12px !important;
      border: 1px solid #ffffff;
    }

    .ant-menu-submenu-selected,
    .ant-menu-item-selected {
      .ant-menu-title-content {
        box-sizing: border-box;
        height: 40px;
        background: #f6f8fa !important;
        border-width: 1px 1px 0px 1px !important;
        border-style: solid !important;
        border-color: #e1e5ea !important;
        border-radius: 4px 4px 0px 0px !important;
      }
    }
  }

  .ant-layout-sider {
    width: 30% !important;
    min-width: 250px !important;

    &.sider-wrapper {
      background-color: #fff;

      .ant-menu-item {
        background-color: #f6f8fa;
        color: #000;
        margin-bottom: 10px;
      }

      .ant-menu-item-selected {
        background-color: #2f7df6;
        color: #fff;
      }
    }

    .sider-tabs {
      padding: 20px 10px 0 20px;
    }
  }
  .eiq-tabs .ant-tabs-content-holder {
    min-height: unset !important;
  }


  @media screen and (min-width: 1600px) {
    //Top menu
    .hotkey-link {
      font: normal normal 500 15px/normal "Work Sans";
    }


    // Breadcrumbs
    .ant-breadcrumb-link {
      font: normal normal 500 20px/normal 'Work Sans', sans-serif !important;
      text-transform: uppercase;
    }



    // Info block
    .tabs-title-wrapper {
      span:nth-child(1) {
        font: normal normal 500 20px/normal 'Work Sans', sans-serif !important;
        text-transform: uppercase;
      }
      span:nth-child(2) {
        font: normal normal 400 16px/normal 'Work Sans', sans-serif !important;
      }
    }
    .alert-block-container {
      span:nth-child(1) {
        font: normal normal 600 14px/normal 'Work Sans', sans-serif !important;
      }
      font: normal normal 400 16px/18px 'Work Sans', sans-serif !important;
    }
    .info-field-lable {
      font: normal normal 400 14px/14px 'Work Sans', sans-serif !important;
    }
    .info-field-value {
      font: normal normal 500 16px/18px 'Work Sans', sans-serif !important;
    }

    .contact-phone__title {
      font: normal normal 500 16px/18px 'Work Sans', sans-serif !important;
    }

    .eiq-secure__title {
      font: normal normal 500 16px/18px 'Work Sans', sans-serif !important;
    }
    .eiq-secure__vehicles-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .vehicle-info {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif !important;
    }
    .vehicle-tags {
      .ant-tag {
        font: normal normal 400 14px/14px 'Work Sans', sans-serif !important;
      }
    }

    // Guest list
    .guest-list__top-actions {
      .ant-btn {
        font: normal normal 500 16px/normal 'Work Sans', sans-serif!important;
      }
    }

    .ant-tabs-tab-btn {
      font: normal normal 500 20px/normal 'Work Sans', sans-serif!important;
      text-transform: uppercase;
    }


    .blue-alert {
      font: normal normal 400 16px/normal 'Work Sans', sans-serif!important;
    }

    .ant-row .eiq-secure-traffic-result {
      font: normal normal 500 18px/normal 'Work Sans', sans-serif!important;
    }

    .search-filter {
      max-width: 600px;
    }

    .go-back .hotkey-link__label {
      font: normal normal 500 18px/21px 'Work Sans', sans-serif!important;
    }
    @include font-16;
  }
}

@media screen and (min-width: 1600px) {


  // Filter columns
  .so-column-filter {
    width: 258px;
    padding: 8px 12px 12px 8px!important;

    .ant-select-item-option-content {
      font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
    }

    .column-visibility__header {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif!important;

      button.ant-btn > span {
        font: normal normal 500 18px/1 'Work Sans', sans-serif!important;
      }
    }

    @include font-16;
  }

  // Modal
  .custom-modal--so .ant-modal-content {
     .ant-modal-header .ant-modal-title {
        font: normal normal 500 20px/1 'Work Sans', sans-serif!important;
     }

    .confirm-modal-body {
      font: normal normal 400 16px/24px 'Work Sans', sans-serif !important;
    }

    @include font-16;


    .eiq-secure-key-info-row {
      gap: 15px;

      &__title,  &__text {
        font: normal normal 400 18px/18px 'Work Sans', sans-serif;
      }
      &__text {
        text-align: right;
      }
    }
  }


  .column-visibility.so-dropdown {
    margin-right: -16px!important;
    border-radius: 4px;
    border: 2px solid #87B6FF;
    background: #FFF;
    box-shadow: 0px 0px 0px 2px rgba(135, 182, 255, 0.30);
    width: 200px;
    .ant-select-item-option-content {
      font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
    }

    .ant-checkbox-wrapper {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
    }

    .column-visibility__header {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif!important;

      button.ant-btn > span {
        font: normal normal 500 18px/1 'Work Sans', sans-serif!important;
      }
    }
  }

  .so-select {
    .ant-select-item-option-content {
      font: normal normal 400 16px/40px 'Work Sans', sans-serif !important;
    }
  }

  .column-check-filter.so-dropdown  {
    display: flex;
    flex-direction: column;
     gap: 14px;

    .ant-select-item-option-content {
      font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
    }

    .ant-checkbox-group {
      gap: 14px;
    }
    .ant-checkbox-wrapper {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
    }

    .column-visibility__header {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif!important;

      button.ant-btn > span {
        font: normal normal 500 18px/1 'Work Sans', sans-serif!important;
      }
    }
  }

  .eiq-select-days.so-dropdown {

    .ant-select-item-option-content {
      font: normal normal 400 16px/40px 'Work Sans', sans-serif!important;
    }

    .ant-select-tree-title {
      font: normal normal 400 16px/18px 'Work Sans', sans-serif!important;
    }

    .ant-select-tree-treenode {
      min-height: 40px;
      align-items: center!important;

      .ant-select-tree-node-content-wrapper {
        background: transparent!important;
      }
    }
  }

  .eiq-secure-panel {
    .ant-select-multiple {
      .ant-select-selection-item {
        font:  normal normal 400 16px/20px "Work Sans", sans-serif !important
      }
    }
  }


  .range-date__label {
    font: normal normal 400 18px/14px "Work Sans", sans-serif!important;
    margin-bottom: 4px;
  }

  .ant-notification-notice-message {
    font: normal normal 400 16px/14px "Work Sans", sans-serif!important;
  }
}
