.range-date {

  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    font: normal normal 400 12px/14px 'Work Sans', sans-serif;
    color: #292B2E;
    display: block;
  }

  input {
    color: #292B2E!important;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    &::placeholder {
      color: #505762!important;
    }
  }

  .range-date__content {
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ant-picker {
      width: 100%;
    }

    & > span {
      font: normal normal 400 14px/18px 'Work Sans', sans-serif;
      color: #292B2E;
    }
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .ant-form-item {
    margin-bottom: 0!important;
  }
}
