.eiq-secure-incident-report-page {
    .ant-collapse-content-box {
        padding: 24px 16px !important;
    }
    .incident-description textarea {
        min-height: 180px !important;
        resize: none !important;
    }
    .ant-collapse, .ant-collapse-content {
        border: none;
    }
    .ant-collapse-item, .ant-collapse-header {
        border-radius: 4px !important;
    }
    .ant-collapse-header {
        @media screen and (max-width: 768px) {
            height: 40px;
            padding: 12px 8px !important;
        }
    }
    .ant-collapse-item:not(.ant-collapse-item-active) {
        margin-bottom: 8px;
    }
    .ant-collapse-item {
        background-color: #F6F8FA;
        border: none;
    }
    .ant-collapse-item-active {
        background-color: #DCDCDC;
        .ant-collapse-header {
            border-radius: 4px;
            @media screen and (max-width: 768px) {
                height: 40px;
                padding: 12px 8px !important;
            }
        }
    }
    .step-header-completed {
        background-color: #DFF6D7;
    }
    .ant-collapse-header-text {
        color: #505762;
        font: normal normal 500 18px/21px 'Work Sans', sans-serif !important;
        @media screen and (max-width: 768px) {
            font-size: 15px !important;
        }
    }
    .step-header-completed .ant-collapse-header-text,
    .ant-collapse-item-active .ant-collapse-header-text {
        color: #292b2e;
    }
    .ant-form-item-label {
        color: #292b2e;
        font: normal normal 400 14px/14px 'Work Sans', sans-serif !important;
    }
    .create-report-title {
        font-size: 24px !important;
        margin: 10px 0;
        @media screen and (max-width: 768px) {
            font-size: 18px !important;
        }
    }
    .collapse-container {
        height: calc(100vh - 260px);
        margin: 40px 0;
        overflow-y: auto;
    }
    .step-header-completed>.ant-collapse-header {
        padding: 10px 16px;
        transition: background-color 0.3s ease;
    }
    .incidentInvolvedVehicleItems {
        display: flex;
        align-items: center;
        gap: 24px;
    }
    .incidentAttachmentUpload {
        display: block;
        height: 200px;
    }
    .manual-info-add-label {
        font: normal normal 400 18px/18px 'Work Sans', sans-serif;
        color: #505762;
    }
    .reporting-person-field .info-field-value {
        font-size: 20px;
        font-weight: 500;
        color: #292B2E;
    }
    .incident-description-view, .info-field-value {
        font: normal normal 400 16px/14px 'Work Sans', sans-serif;
        color: #292B2E;
    }
    .incident-description-view {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .info-field-label {
        font: normal normal 400 14px/14px 'Work Sans', sans-serif;
        color: #505762;
    }
    .info-field {
        margin-bottom: 10px;
    }
    .incidentDateItem {
        margin-bottom: 0;
    }
    .incidentEditModeActions, .incidentViewModeActions {
        @media screen and (max-width: 768px) {
            .ant-space, .ant-space-item, button {
                width: 100%;
           }
        }
    }
    .ant-upload-select {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #AEB5BE;
        border-radius: 16px;
        padding: 24px 8px;
        margin-bottom: 8px;
        .ant-upload {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            .ant-upload-drag-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #E1E5EA;
                border-radius: 48px;
                padding: 5px;
                width: 56px;
                height: 56px;
            }
            .ant-upload-text {
                font: normal normal 400 16px/15px 'Work Sans', sans-serif;
                letter-spacing: 0.02em;
                color: #292B2E;
                text-align: center;
            }
        }
    }
    .ant-upload-list-item-container {
        .ant-upload-list-item {
            background-color: #F6F8FA;
            height: 52px !important;
            padding: 6px 8px !important;
            .ant-upload-list-item-thumbnail {
                width: 40px !important;
                height: 40px !important;
            }
            .ant-upload-list-item-name {
                font: normal normal 400 14px/14px 'Work Sans', sans-serif;
                letter-spacing: 0.02em;
                color: #505762;
            }
        }
    }
    &.create {
        .ant-collapse-item:nth-of-type(1) .ant-collapse-content-box,
        .ant-collapse-item:nth-of-type(2) .ant-collapse-content-box,
        .ant-collapse-item:nth-of-type(3) .ant-collapse-content-box, {
            padding-bottom: 0 !important;
        }
    }
}
