.eiq-secure-general {
  .eiq-secure-general__alert-vocation {
  }

  .vocation-container {
    box-sizing: border-box;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d1e3ff;
    background: rgba(47, 125, 246, 0.1);

    .info-field-value {
      font: normal normal 500 14px/18px 'Work Sans', sans-serif;
      color: #505762;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .vocation-container__from-to {
      display: flex;
      > div {
        flex: 1;
      }

      @media screen and (max-width: 768px) {
        display: flex;
        gap: 12px;
        flex: 1 0 100%;
      }
    }
    > div:first-child {
      flex: 1 0 33%;
    }
    > div:last-child {
      flex: 1 0 67%;
    }
  }

  .vocation-container.home {
    border-radius: 4px;
    border: 1px solid #e1e5ea;
    background: #fff;
  }
  .column-content > * {
    margin-bottom: 20px;
  }
  .contact-cars-row {
    margin-right: 0px !important;
  }
  .eiq-secure-general__resident-name{
    font: normal normal 500 24px/28px 'Work Sans', sans-serif;
    color: #505762;
    margin-bottom: 17px;
  }
}
