.alert-block-container {
  font: normal normal 400 12px/14px 'Work Sans', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #ed6615;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  gap: 4px;
  min-height: 68px;

  span:nth-child(1) {
    color: white;
    font-weight: 800 !important;
  }

  span:nth-child(2) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
    color: white;
  }

  &.med {
    background-color: #ffebea;
  }

  border-radius: 4px;
}
