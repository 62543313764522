.btn-container {
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    color: rgb(245, 102, 102);
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
