.eiq-secure__vehicles-list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .eiq-secure__vehicles-item {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    color: #292B2E;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  }
}
