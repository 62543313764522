.eiq-tabs {
  border: none;

  &.ant-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: calc(100% - 45px);
      }
    }
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-content-holder {
    min-height: 348px !important;
  }
}
