.titleValue {
  font-weight: 500;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 18px;
}
.modaltextarea {
  min-height: 100px !important;
}

.streetNumberWrapper {
  :global(.ant-space-item):nth-child(1) {
    width: 60% !important;
  }
}
