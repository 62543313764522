.custom-notification {
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: white;
  cursor: default;
  &.success {
    background-color: #14c68a;
  }
  &.error {
    background-color: #e86056;
  }
  .main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .notification {
      font-family: "Work Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #f6f8fa;
      margin-bottom: 0;
      padding-right: 8px;
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .time {
        margin: 0;
      }
      .details {
        color: #FFFFFF;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .body, .footer {
      width: 100%;
      gap: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .close-btn, .arrow-btn {
    cursor: pointer;
    display: flex;
  }
  .arrow-btn {
    svg {
      height: 14px;
      width: 14px;
    }
  }
  &.last-notification {
    width: 352px;
    align-items: center;
    .notification {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .body {
      align-items: center;
    }
  }
}
