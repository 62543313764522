.column-visibility {
  background: #FFFFFF;
  padding: 8px 15px;
  overflow-y: auto;
  max-height: 350px;

  .ant-form-item {
    margin-bottom: 0!important;
  }

  .column-visibility-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.column-visibility__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span { // title
    font: normal normal 500 14px/18px 'Work Sans', sans-serif;
    color: #505762;
  }
}
