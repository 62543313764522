.anonymous-registration {
    height: fit-content;
    min-height: 100%;

    .anonymous-registration-btn {
        width: 200px;
        margin-top: 15px;
    }

    .btn-description{
        margin-bottom: 15px;
    }
}