.modalBody {
    font: normal normal 400 14px/18px 'Work Sans';
    margin-bottom: 10px;

    .name {
        font-weight: 500;
    }
}

.button {
    width: 153px;
}