.photoContainer {
    span {
        display: flex;
        align-items: center;
        gap: 16px;
        outline: none !important;
    }
    .petImage {
        width: 104px;
        height: 104px;
        object-fit: contain;
        cursor: pointer;
    }
    .petAddImage {
        cursor: pointer;
    }
}
