.contact-phones {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contact-phone__title {
    margin: 0;
    padding: 0;
    color: #505762;
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .contact-phone__subtitle {
    font: normal normal 400 14px/normal 'Work Sans', sans-serif;
  }
}
