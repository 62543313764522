.eiq-secure-key-info-row {
  display: flex;
  justify-content: space-between;
  gap: 4px;

  &__title,  &__text {
    color: #292B2E;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
    margin: 4px 0;
  }
  &__text {
    text-align: right;
  }
}
