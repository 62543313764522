.container > .wrapper > .switch{
    padding-left: 20px; 
}

.container {
    .title{
        font-weight: 500;
    }
}

.wrapper {
    .switch {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        height: 25px;
        width: 300px;
    }

   
    .description{
        font-style: italic;
        font-size: 12px;
    }
    
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
    gap: 10px;

    @media screen and (max-width: 1030px) {
        flex-wrap: wrap;
        .description:last-child {
            flex-basis: 100%;
        }
        .switch {
            padding-left: 0 !important;
        }
    }
}

.trafficLogixNotification {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    height: 25px;
    margin-bottom: 10px;

    @media screen and (max-width: 1030px) {
        padding-left: 0 !important;
    }
}

.EIQIntelliTicketConfiguration {
    padding-left: 20px;
    max-width: 300px;

    @media screen and (max-width: 1030px) {
        padding-left: 0 !important;
    }
}