.eiq-secure-main-page {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  .contacts-info-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;

    .contact-phone {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      margin-top: 12px;
    }

    div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .eiq-secure-info {
    max-width: 474px;
  }

  .guest-list__actions {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 993px) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.action-buttons-container {
  display: flex;
  gap: 12px;
}

.address-row {
  margin-top: 8px;
}

.eiq-secure-subtitle {
  color: #505762;
  font: normal normal 500 14px/18px 'Work Sans', sans-serif !important;
}

.mobile-guest-list-actions {
  display: flex;
  flex-direction: column;
  padding: 16px 0 24px 0;
  gap: 12px;

  button {
    width: 100% !important;
  }
}

.admit-resident-btn {
  position: absolute;
  top: -55px;
  right: 0;
}

.button-space {
  display: flex;
  gap: 8px;
}

.eiq-secure-resident-list-filters{
  margin-bottom: 16px;
  > button {
    height: 41px;
  }
}


//.so__resident-box {
//  .ant-tabs {
//    display: flex;
//    flex-direction: column;
//    flex: 1;
//
//    .ant-tabs-content-holder {
//      display: flex;
//      flex-direction: column;
//      flex: 1;
//
//      .ant-tabs-content {
//        display: flex;
//        flex-direction: column;
//        flex: 1;
//
//        .ant-tabs-tabpane-active {
//          display: flex;
//          flex-direction: column;
//          flex: 1;
//
//        }
//      }
//    }
//  }
//}
