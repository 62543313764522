.drawer-content-wrapper {
    background-color: #ffffff;
    border-radius:  8px;
    box-shadow: 0px 0px 7px rgba(51, 53, 55, 0.12);
    padding: 16px 12px;
}

.card-wrapper {
    box-shadow: 0px 0px 7px rgba(51, 53, 55, 0.12);
    border-radius: 8px;
    background-color: #ffffff;
}

.info-block-conteiner{
    margin-top: 12px;
}

.open-close-container {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #F6F8FA;
    transition: height 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.open-close-title-container {
    background-color: #F6F8FA;
    position: relative;
    color: #505762;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;

    .open-close-title {
        margin: 0 auto;
        padding-top: 15px;
        padding-bottom: 17px;

        .open {
            transform: rotate(180deg);
            top: 24px;
        }

        .close {
            top: 18px
        }

        .info {
            size: 14px;
            line-height: 18px;
            font-weight: 500;
            margin-right: 6px;
        }
    }
}
