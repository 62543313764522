.eiq-secure-traffic-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  .traffic-content {
    flex: 1;
    >.ant-col {
      display: flex;
    }
    .ant-card-body {
      height: 100%;
    }
  }
  .eiq-secure-traffic-result {
    color:#292B2E;
    font: normal normal 500 14px/18px 'Work Sans', sans-serif!important;
    margin-bottom: 16px;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }

  .unlisted-guest-from-lrp {
    background-color: #ffa39e !important;
  }
}
