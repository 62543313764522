.vehicle-info-block {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  .vehicle-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .vehicle-tags {
    display: flex;
  }
}