.login-layout {
  background: #F6F8FA;
  //min-height: 100vh;
  height: 100%;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  position: relative;
  font:  normal normal 400 14px/18px 'Work Sans', sans-serif;
  color: #292B2E;
  width: 100%;

  button.ant-btn-primary {
    background: #2F7DF6;
    border-radius: 4px;
    font:  normal normal 400 14px/18px 'Work Sans', sans-serif;
  }

  .title {
    font:  normal normal 500 18px/21px 'Work Sans', sans-serif;
    margin-bottom: 12px;
  }

  .text {
    display: flex;
    font:  normal normal 400 14px/18px 'Work Sans', sans-serif;
    align-items: center;
  }


  // Input improvements
  .ant-form-item {
    margin-bottom: 20px;

    .ant-form-item-label {
      line-height: 1;
      max-height: 22px;
      label {
        font:  normal normal 400 12px/14px 'Work Sans', sans-serif;
      }
    }
    .ant-form-item-required:before {
      content: unset!important;
    }
    .ant-form-item-explain-error {
      display: none!important;
    }
  }

  .forgot-password {
    line-height: 1;
    margin-bottom: 32px;
  }

  a {
    font:  normal normal 400 14px/18px 'Work Sans', sans-serif;
  }

  .action-line {
    display: flex;
    justify-content: center;
    line-height: 1;
  }

  .action-two-lines {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    > * {
      flex: 1
    }
    > :first-child {
      margin-right: 20px;
    }
  }
}



.auth-wrapper {
  margin-left: 16px;
  margin-right: 16px;
  width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 12px;
  background: #FFFFFF;
  border: 2px solid #87B6FF;
  box-shadow: 0 0 0 2px rgba(135, 182, 255, 0.3);

  @media screen and (max-width: 761px) {
    width: 100% !important;
  }
}

