.eiq-secure-guest-add-edit-modal {
  .checkbox-group {
    margin-top: 10px;
    margin-bottom: 24px;

    .ant-picker {
      width: 100%;
    }
  }

  .range-group {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 767px) {
      align-items: flex-start;

      .day-weeks  {
        padding-left: 24px;
      }
    }
  }
}
