.delete-modal-with-tables {
  .street-label {
    margin: 0;
  }

  .street-name {
    font: normal normal 500 14px/18px 'Work Sans', sans-serif;
    margin-bottom: 12px;
    margin-top: 3px;
  }

  .table-title {
    margin: 12px 0;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;
  }

  .table-wrapper {
    margin-bottom: 15px;

    .cc-table {
      min-height: initial !important;
    }
  }

  .button {
    width: 203px;
  }
}
