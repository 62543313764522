.rejectButton {
  background-color: #e86056 !important;
}

.modalButton {
  width: 159px;
}

.modalBody {
  padding: 0px 10px;
}

.modalTextArea {
  resize: none !important;
  width: 330px !important;
  height: 94px !important;
}

.approveButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.rejectReason {
  font-weight: 500;
}
