.wrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 8px;
}

.label {
    font: normal normal 400 12px/14px 'Work Sans';
    margin-top: 12px;
    display: block;
}

.periodWrapper {
    margin-bottom: 16px;
}