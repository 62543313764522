.notificationContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font: normal normal 400 14px/18px 'Work Sans', sans-serif;

    .lastUpdated {
        font-size: 12px;
        line-height: 14px;
        opacity: 0.5;
    }

    .editButton {
        width: 80px;
    }

    .contacts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid #E1E5EA;
        border-radius: 4px;
        padding: 8px 16px;

        .phoneContainer, .emailField {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
}

.title {
    font: normal normal 500 14px/18px 'Work Sans', sans-serif;
}