.tabs-title-wrapper {
    margin-right: 20px;
    color: black;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 4px;

    span:nth-child(1){
        margin-right: 12px;
    }

    span:nth-child(2) {
        color: #505762;
        font: normal normal 400 12px/14px 'Work Sans', sans-serif;
    }
}
