.eiq-secure-guest-list__card-header{
    .guest-list__search-wrapper{
        display: flex;
        flex-direction: row;
        gap: 10px;

        > button {
            height: 41px;
        }
    }
    
    .column{
        flex-direction: column;
    }

    .guest-address-info{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    @media screen and (max-width: 993px) {
        .guest-address-info{
            display: block;
            width: 100%;

            .back-button{
                margin-bottom: 10px;
            }
        }
    }
        
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}