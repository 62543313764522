.resident-license {
  display: flex;
  flex-direction: column;
  gap: 20px;


  .container-row {
    padding-left: 16px;
    padding-right: 16px;
  }

  .vehicle-info-block {
    flex-wrap: nowrap;
    align-items: center;
  }
}
