.resident-page {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex:1;
}

.action-buttons-container {
  display: flex;
  gap: 12px;
}
